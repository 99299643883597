@font-face {
    font-family: montserratBlack;
    src: url("../../assets/fonts/Montserrat-Black.ttf");
}

@font-face {
    font-family: montserratBlackItalic;
    src: url("../../assets/fonts/Montserrat-BlackItalic.ttf");
}

@font-face {
    font-family: montserratBold;
    src: url("../../assets/fonts/Montserrat-Bold.ttf");
}

@font-face {
    font-family: montserratBoldItalic;
    src: url("../../assets/fonts/Montserrat-BoldItalic.ttf");
}

@font-face {
    font-family: montserratExtraBold;
    src: url("../../assets/fonts/Montserrat-ExtraBold.ttf");
}

@font-face {
    font-family: montserratExtraBoldItalic;
    src: url("../../assets/fonts/Montserrat-ExtraBoldItalic.ttf");
}

@font-face {
    font-family: montserratExtraBoldItalic;
    src: url("../../assets/fonts/Montserrat-ExtraBoldItalic.ttf");
}

@font-face {
    font-family: montserratExtraLight;
    src: url("../../assets/fonts/Montserrat-ExtraLight.ttf");
}

@font-face {
    font-family: montserratExtraLightItalic;
    src: url("../../assets/fonts/Montserrat-ExtraLightItalic.ttf");
}

@font-face {
    font-family: montserratItalic;
    src: url("../../assets/fonts/Montserrat-Italic.ttf");
}

@font-face {
    font-family: montserratLight;
    src: url("../../assets/fonts/Montserrat-Light.ttf");
}

@font-face {
    font-family: montserratLightItalic;
    src: url("../../assets/fonts/Montserrat-LightItalic.ttf");
}

@font-face {
    font-family: montserratMedium;
    src: url("../../assets/fonts/Montserrat-Medium.ttf");
}

@font-face {
    font-family: montserratMediumItalic;
    src: url("../../assets/fonts/Montserrat-MediumItalic.ttf");
}

@font-face {
    font-family: montserratRegular;
    src: url("../../assets/fonts/Montserrat-Regular.ttf");
}

@font-face {
    font-family: montserratSemiBold;
    src: url("../../assets/fonts/Montserrat-SemiBold.ttf");
}

@font-face {
    font-family: montserratSemiBoldItalic;
    src: url("../../assets/fonts/Montserrat-SemiBoldItalic.ttf");
}

@font-face {
    font-family: montserratThin;
    src: url("../../assets/fonts/Montserrat-Thin.ttf");
}

@font-face {
    font-family: montserratThinItalic;
    src: url("../../assets/fonts/Montserrat-ThinItalic.ttf");
}