* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

body {
  background-color: var(--color-white);
}

a {
  color: unset;
  text-decoration: none;
}

.navbar__bg {
  background-color: var(--color-theme);
}

.slide-right {
  -webkit-animation: slide-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: slide-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

/* ----------------------------------------------
* Generated by Animista on 2023-1-6 14:58:21
* Licensed under FreeBSD License.
* See http://animista.net/license for more info. 
* w: http://animista.net, t: @cssanimista
* ---------------------------------------------- */

/**
* ----------------------------------------
* animation slide-right
* ----------------------------------------
*/

@-webkit-keyframes slide-right {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  100% {
    -webkit-transform: translateX(100px);
    transform: translateX(100px);
  }
}

@keyframes slide-right {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  100% {
    -webkit-transform: translateX(100px);
    transform: translateX(100px);
  }
}

.section__padding {
  padding: 6.4rem 9.6rem;
}

.section__margin {
  padding: 6.4rem 9.6rem;
}