.signup-auth-container {
    display: flex;
}

.signup-scroll {
    overflow: scroll;
    scroll-behavior: smooth;
    height: 100vh;
    width: 40%;
}

.signup-detail_view {
    display: flex;
    padding-bottom: 1rem;
    flex-direction: column;
    flex: 1;
    /* align-items: center; */
}

.signup-detail_view div {
    display: flex;
    flex-direction: column;
}

.signup-detail_view h1 {
    align-self: center;
    text-align: center;
    font-family: montserratSemiBold;
    font-size: 28px;
    color: #1E1E1E;
}

.signup-detail_view h2 {
    align-self: center;
    font-family: montserratRegular;
    padding-bottom: 4%;
    padding-top: 1rem;
    font-size: 14px;
    line-height: 26px;
    text-align: center;
    color: #666666;
    width: 30rem;
}

.signup-button-view {
    margin-top: 2rem;
    margin-bottom: 1rem;
}

.signup-or-divider {
    display: flex;
    align-items: center;
    flex-direction: row !important;
    align-self: center;
    width: 80%;
    margin: 30px 0;
    /* Adjust margin as needed */
}

.signup-line {
    flex-grow: 1;
    height: 0.5px;
    background-color: #E2E2E2;
    /* Line color */
}

.signup-or-text {
    margin: 0 20px;
    /* Adjust margin as needed */
    color: #666666;
    font-size: 18px;
    font-family: montserratRegular;
    /* Text color */
}

.signup-social_view {
    display: flex;
    flex-direction: row !important;
    align-self: center;
}

.signup-social_view div {
    display: flex;
    width: 7rem;
    height: 3rem;
    border-radius: 2rem;
    border: 0.8px solid #E2E2E2;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    /* margin-right: 1.5rem; */
}

.signup-social_view img {
    width: 2rem;
    height: 2rem;
    object-fit: cover;
}

.signup-dont_have_account_view {
    display: flex;
    align-self: center;
    cursor: pointer;
}

.signup-dont_have_account_view h3 {
    font-size: 14px;
    font-family: montserratRegular;
    color: #8E8E94;
}

/* ************************** Common Styling ************************* **/
.signup-image_sideView {
    display: flex;
    height: 100vh;
    width: 60%;
}

.signup-image_logo_style {
    width: 5rem;
    height: 5rem;
    margin: 1rem;
}

.signup-image_sideStyle {
    height: 100%;
    width: 100%;
}

/* ************************** Media Queries Styling ************************* **/
@media screen and (max-width: 1250px) {
    .signup-image_sideView {
        display: none;
    }

    .signup-scroll {
        width: 100%;
    }
}

@media screen and (max-width: 500px) {
    .signup-detail_view h1 {
        font-size: 20px;
    }

    .signup-detail_view h2 {
        font-size: 12px;
        width: 20rem;
        line-height: 16px;
    }

    .signup-social_view div {
        width: 5rem;
        height: 2rem;
    }

    .signup-image_logo_style {
        width: 3rem;
        height: 3rem;
        margin: 1rem;
    }

    .signup-social_view img {
        width: 1.5rem;
        height: 1.5rem;
        object-fit: cover;
    }

    .signup-or-divider {
        width: 70%;
        /* Adjust margin as needed */
    }
}