.input_container {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.4rem;
  position: relative;
  align-self: center;
}

.input_container p {
  font-size: 16px;
  font-family: montserratSemiBold;
  line-height: 26px;
  color: #0E191E;
}

.input_container h5 {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #ff3333;
  padding-top: 0.5rem;
}

.input_container_input {
  margin-top: 0.6rem;
  flex-direction: row !important;
  width: 30rem;
  background-color: rgba(217, 217, 217, 0.1);
  border-radius: 0.5rem;
  /* border: 1px solid #D9D9D9; */
  padding: 0.5rem 1rem 0.5rem 1rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.input_container_input input {
  width: 90% !important;
  display: flex;
  background-color: transparent;
  outline: none;
  border: none;
  font-size: 16px;
  line-height: 2rem;
  color: #000000;
}

.input_container_input img {
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
}

::-webkit-input-placeholder {
  color: #9E9E9E;
  font-family: montserratRegular;
  font-size: 14px;
}

.dropdown_container {
  position: absolute;
  border: 1px solid #D9D9D9;
  background-color: rgb(255, 255, 255);
  top: 7rem;
  right: 0;
  width: 193px;
  border-radius: 1rem;
  height: 14rem;
  max-height: 14rem;
  z-index: 10;
  overflow: scroll;
  padding: 1rem 1rem;
  cursor: pointer;
}

.dropdown_container h3 {
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.8em;
  color: #666666;
  margin-bottom: 0.5rem;
}


@media screen and (max-width: 500px) {
  .input_container_input {
    width: 20rem;
  }
}

.custom-textarea {
  width: 100%;
  height: 100px;
  /* Adjust the height as needed */
  box-sizing: border-box;
  background-color: transparent;
  color: black;
  /* Text color inside the textarea */
  border-color: transparent;
  border-radius: 5px;
  font-size: 16px;
}

.custom-textarea::placeholder {
  color: #9E9E9E;
  font-family: montserratMedium;
  font-size: 14px;
}

.custom-textarea:focus {
  border-color: transparent;
  outline: none;
}