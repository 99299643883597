/* ContainerComponent.css */
.enjoy-main-container {
    text-align: center;
    /* Add additional styling for the main container if needed */
    padding: 4rem 6rem 4rem 6rem;
}

.enjoy-logo-container img {
    width: 100px;
    height: 100px;
}

.enjoy-logo-title {
    background: linear-gradient(45deg, #001F7A, #2F80D8);
    /* Gradient color */
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    font-size: 16px;
    line-height: 24px;
    font-family: montserratMedium;

}

.enjoy-title {
    font-size: 48px;
    font-family: montserratSemiBold;
    margin-top: 20px;
    line-height: 72px;
    color: #1E1E1E;
    /* Add additional styling for the title if needed */
}

.enjoy-title-description {
    font-size: 20px;
    font-family: montserratRegular;
    margin-top: 20px;
    line-height: 30px;
    color: #666666;
    /* Add additional styling for the title if needed */
}

.enjoy-list-container {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
}

.enjoy-list-item {
    width: 26.5%;
    /* Adjust the width based on your preference */
    background-color: white;
    /* border: 1px solid #E2E2E2; */
    border-radius: 2rem;
    padding: 1rem 1rem 1rem 1rem;
    /* Border color */
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
    /* Ensure the positioning context for z-index */
    /* Shadow */
    /* Add additional styling for the list items if needed */
}

.enjoy-item-image {
    width: 250px;
    height: 250px;
    /* Adjust the width based on your preference */
    /* Add additional styling for the item image if needed */
}

.enjoy-item-description {
    font-size: 16px;
    font-family: montserratRegular;
    margin-top: 10px;
    line-height: 24px;
    color: #666666;
    /* Add styling for the item description */
}

@media (max-width: 1400px) {
    .enjoy-item-image {
        width: 250px;
        height: 250px;
    }

    .enjoy-item-description {
        font-size: 14px;
        /* Add styling for the item description */
    }
}

@media (max-width: 1200px) {
    .enjoy-item-image {
        width: 200px;
        height: 200px;
    }

    .enjoy-item-description {
        font-size: 14px;
        /* Add styling for the item description */
    }
}

@media (max-width: 1000px) {
    .enjoy-list-container {
        display: flex;
        flex-direction: column;
    }

    .enjoy-list-item {
        width: auto;
        padding: 2rem 0rem 0rem 0rem;
    }
}

@media (max-width: 800px) {
    .enjoy-main-container {
        text-align: center;
        padding: 0rem 2rem 0rem 2rem;
    }

    .enjoy-title {
        font-size: 30px;
        line-height: 42px;
        /* Add additional styling for the title if needed */
    }

    .enjoy-title-description {
        font-size: 14px;
        line-height: 20px;
        /* Add additional styling for the title if needed */
    }

    .enjoy-list-container {
        display: flex;
        flex-direction: column;
    }

    .enjoy-list-item {
        width: auto;
        padding: 2rem 0rem 0rem 0rem;
    }

    .enjoy-item-image {
        width: 200px;
        height: 200px;
    }

    .enjoy-item-description {
        font-size: 14px;
        /* Add styling for the item description */
    }
}