/* ContainerComponent.css */
.profile-main-container {
    /* Add additional styling for the main container if needed */
    padding: 4rem 15rem 4rem 15rem;
}

.profile-main-container-centerAlign {
    text-align: center;
}

.profile-logo-container img {
    width: 100px;
    height: 100px;
}

.profile-logo-title {
    background: linear-gradient(45deg, #001F7A, #2F80D8);
    /* Gradient color */
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    font-size: 14px;
    line-height: 24px;
    font-family: montserratMedium;

}

.profile-title {
    font-size: 42px;
    font-family: montserratSemiBold;
    margin-top: 20px;
    line-height: 72px;
    color: #000000;
    /* Add additional styling for the title if needed */
}

.profile-title-description {
    font-size: 20px;
    font-family: montserratRegular;
    margin-top: 20px;
    line-height: 30px;
    color: #666666;
    /* Add additional styling for the title if needed */
}

.delete-account {
    font-size: 18px;
    font-family: montserratSemiBold;
    margin-top: 30px;
    line-height: 26px;
    color: red;
    text-align: center;
    cursor: pointer;
}

@media (max-width: 800px) {

    /* ContainerComponent.css */
    .profile-main-container {
        /* Add additional styling for the main container if needed */
        padding: 0rem 2rem 0rem 2rem;
    }

    .delete-account {
        font-size: 18px;
        font-family: montserratSemiBold;
        margin-top: 30px;
        line-height: 26px;
        color: red;
        text-align: center;
        cursor: pointer;
    }
}