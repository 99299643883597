/* ContainerComponent.css */
.courier-main-container {
    text-align: center;
    /* Add additional styling for the main container if needed */
    padding: 4rem 6rem 4rem 6rem;
}

.courier-logo-container img {
    width: 100px;
    height: 100px;
}

.courier-logo-title {
    background: linear-gradient(45deg, #001F7A, #2F80D8);
    /* Gradient color */
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    font-size: 16px;
    line-height: 24px;
    font-family: montserratMedium;
}

.courier-title {
    font-size: 48px;
    font-family: montserratSemiBold;
    margin-top: 20px;
    line-height: 72px;
    color: #1e1e1e;
    /* Add additional styling for the title if needed */
}

.courier-title-description {
    font-size: 20px;
    font-family: montserratRegular;
    margin-top: 20px;
    line-height: 30px;
    color: #666666;
    text-align: left;
    /* Add additional styling for the title if needed */
}

.courier-main-container p {
    font-size: 20px;
    font-family: montserratRegular;
    margin-top: 20px;
    line-height: 30px;
    color: #666666;
    text-align: left;
}

.courier-custome-list-container {
    display: flex;
    margin-top: 50px;
    width: 100%;
    justify-content: space-between;
    text-align: left;
    /* Add additional styling for the list container if needed */
}

.courier-custome-title {
    font-size: 52px;
    font-family: montserratBold;
    margin-top: 20px;
    line-height: 64px;
    color: rgba(30, 30, 30, 1);
    letter-spacing: -0.3px;
    /* Add additional styling for the title if needed */
}

.courier-custome-title-description {
    font-size: 18px;
    font-family: montserratRegular;
    margin-top: 20px;
    line-height: 26px;
    letter-spacing: -0.3px;
    color: #666666;
    text-align: left;
    /* Add additional styling for the title if needed */
}

.courier-custome-list-container p {
    font-size: 18px;
    font-family: montserratRegular;
    margin-top: 20px;
    line-height: 26px;
    color: rgba(102, 102, 102, 1);
    text-align: left;
    letter-spacing: -0.3px;
    /* Add additional styling for the title if needed */
}

.iPhone-adjust {
    width: 523px;
    height: 560px;
    margin-left: 30px;
}

.store-buton {
    display: flex;
}

.store-buton img {
    width: 120px;
    height: auto;
    margin: 50px 10px;
}

@media (max-width: 1050px) {
    .courier-title {
        font-size: 42px;
        line-height: 62px;
        /* Add additional styling for the title if needed */
    }

    .courier-main-container p {
        font-size: 16px;
    }

    .courier-title-description {
        font-size: 16px;
        line-height: 24px;
        /* Add additional styling for the title if needed */
    }

    .courier-custome-list-container {
        margin-top: 0px;
        display: flex;
        flex-direction: column;
    }
}


@media (max-width: 800px) {
    .courier-main-container {
        padding: 02rem 2rem 0rem 2rem;
    }

    .courier-main-container p {
        font-size: 14px;
    }

    .courier-title {
        font-size: 30px;
        line-height: 20px;
        /* Add additional styling for the title if needed */
    }

    .courier-title-description {
        font-size: 14px;
        line-height: 24px;
        /* Add additional styling for the title if needed */
    }


    .courier-custome-title {
        font-size: 36px;
    }

    .courier-custome-title-description {
        font-size: 14px;
    }

    .courier-custome-list-container p {
        font-size: 14px;
    }

    .iPhone-adjust {
        width: 350px;
        height: 450px;
        margin-left: 30px;
    }
}