/* ContainerComponent.css */

.runner-main-container {
    text-align: center;
    /* Add additional styling for the main container if needed */
    padding: 4rem 6rem 4rem 6rem;
}

.runner-logo-container img {
    width: 100px;
    height: 100px;
}

.runner-logo-title {
    background: linear-gradient(45deg, #001F7A, #2F80D8);
    /* Gradient color */
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    font-size: 16px;
    line-height: 24px;
    font-family: montserratMedium;
}

.runner-title {
    font-size: 48px;
    font-family: montserratSemiBold;
    margin-top: 20px;
    line-height: 72px;
    color: #1E1E1E;
    /* Add additional styling for the title if needed */
}

.read-more {
    cursor: pointer;
}

.runner-title-description {
    font-size: 20px;
    font-family: montserratRegular;
    margin-top: 20px;
    line-height: 30px;
    color: #666666;
    text-align: left;
    /* Add additional styling for the title if needed */
}

.runner-title-below {
    font-size: 24px;
    font-family: montserratSemiBold;
    margin-top: 50px;
    line-height: 36px;
    color: #1E1E1E;
}

.runner-title-description-below {
    font-size: 16px;
    font-family: montserratRegular;
    margin-top: 20px;
    line-height: 24px;
    color: #666666;
    /* Add additional styling for the title if needed */
}

.runner-dropdown-option {
    display: flex;
    margin: 20px;
    align-items: flex-start;
}

.runner-dropdown-option span {
    font-size: 16px;
    font-family: montserratMedium;
    line-height: 24px;
    color: #1E1E1E;
    text-align: left;
}

.runner-dropdown-option img {
    width: 25px;
    height: 25px;
    margin-right: 10px;
}

.main-map-container {
    display: flex;
    margin-top: 20px;
    justify-content: center;
}

.runner-dynamic-map-container {
    width: 20%;
    /* Full width */
    margin-left: 50px;
    /* padding-top: 56.25%; */
    /* Percentage for 16:9 aspect ratio */
    position: relative;
    /* For absolute positioning of the map inside */
}


/* Media Query for screens between 800px and 1050px */
@media (max-width: 1050px) {
    .runner-title {
        font-size: 36px;
        line-height: 42px;
        /* Add additional styling for the title if needed */
    }

    .runner-title-description {
        font-size: 16px;
        line-height: 20px;
        /* Add additional styling for the title if needed */
    }
}

/* Media Query for screens below 800px */
@media (max-width: 800px) {
    .runner-dropdown-option {
        display: flex;
        margin: 10px;
        align-items: flex-start;
    }

    .runner-dropdown-option span {
        font-size: 14px;
    }

    .runner-dropdown-option img {
        width: 20px;
        height: 20px;
        margin-right: 10px;
    }

    .main-map-container {
        width: 80%;
        display: flex;
        flex-direction: column;
        margin-top: 20px;
    }

    .runner-main-container {
        padding: 2rem;
        /* Adjust padding for smaller screens */
    }

    .runner-title {
        font-size: 30px;
        line-height: 36px;
        /* Add additional styling for the title if needed */
    }

    .runner-title-description {
        font-size: 14px;
        line-height: 24px;
        /* Add additional styling for the title if needed */
    }
}