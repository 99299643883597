.your-container {
    width: calc(33.33% - 10px);
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 10px;
    box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.1);
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
}

.order-number,
.status {
    color: #001F7A;
    font-size: 14px;
    font-family: montserratRegular;
    line-height: 16px;
}

.divider {
    border: 1px solid #EFEFF4;
    margin: 10px 0;
}

.row-view {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.row-text {
    color: #333333;
    font-size: 16px;
    font-family: montserratSemiBold;
    font-weight: 600;
    line-height: 20px;
}

.row-icon {
    width: 32px;
    height: 32px;
}

.marker-view {
    position: relative;
    margin-bottom: 20px;
}

.location-icon {
    width: 5%;
    height: auto;
    margin-right: 10px;
}

.marker-text,
.lower-text {
    color: #000000;
    font-family: montserratRegular;
    font-size: 14px;
    line-height: 18px;
}

.connector-line {
    position: absolute;
    top: 50%;
    left: 2.6%;
    transform: translate(-50%, -50%);
    border-left: 2px dotted #C8C7CC;
    height: 1.6rem;
}

.footer {
    display: flex;
    justify-content: space-between;
    border-radius: 4px;
}

.footer-left,
.footer-right {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin: 10px;
    padding: 8px 20px;
    border-radius: 2rem;
}

.footer-left {
    background-color: #D8FFDC;
}

.footer-right {
    background-color: #D8EAFF;
}

.calendar-icon,
.clock-icon {
    width: 16px;
    height: 16px;
    margin-right: 10px;
}

.calendar-icon {
    color: #00960F;
}

.clock-icon {
    color: #0075FF;
}

.date,
.time {
    font-size: 12px;
    line-height: 16px;
    font-family: 'Montserrat', sans-serif;
}

.date {
    color: #00960F;
}

.time {
    color: #0075FF;
}

/* Responsive adjustments */
@media (max-width: 800px) {

    .header,
    /* .row-view, */
    /* .footer,  */
    .marker-view {
        flex-direction: column;
        align-items: flex-start;
    }

    .status {
        margin-top: 10px;
    }

    /* .footer-left,
    .footer-right {
        justify-content: start;
        padding: 8px 10px;
    } */

    .row-icon,
    .calendar-icon,
    .clock-icon {
        width: 24px;
        height: 24px;
    }

    .row-text,
    .date,
    .time {
        font-size: 12px;
    }

    .your-container {
        width: 100%;
        margin: 0px 20px 10px 20px;
    }
}