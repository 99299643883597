/* mainSlider.css */

.container {
    display: flex;
    flex-direction: row;
    /* Default is row, which is left-to-right */
    background: linear-gradient(to right, #001F7A, #2F80D8);
    color: #fff;
}

.left-section {
    flex: 1;
    padding: 4rem 1rem 4rem 6rem;
}

.left-section p {
    font-size: 24px;
    font-family: montserratRegular;
    line-height: 36px;
    margin-top: 20px;
}

.left-section h1 {
    font-size: 48px;
    font-family: montserratSemiBold;
    line-height: 72px;
}

.left-section span {
    font-size: 20px;
    font-family: montserratRegular;
    line-height: 30px;
}

.right-section {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    margin: 6rem 0rem 1rem 0rem;
}

.custom-button {
    margin-top: 30px;
    background-color: #fff;
    color: #001F7A;
    padding: 10px 20px;
    font-size: 18px;
    line-height: 26px;
    border: none;
    cursor: pointer;
    border-radius: 2em;
    font-family: montserratSemiBold;
}

video {
    width: 100%;
    height: auto;
}


@media (max-width: 1250px) {
    .container {
        display: flex;
        flex-direction: column;
        /* Switch to column layout at 900px or less */
    }

    .left-section {
        padding: 0rem 6rem 0rem 6rem;
        /* Adjust padding for smaller screens */
    }

    .right-section {
        margin: 2rem 0rem 0rem 0rem;
    }
}

@media (max-width: 800px) {
    .left-section {
        padding: 0rem 2rem 0rem 2rem;
        /* Adjust padding for smaller screens */
    }

    .left-section p {
        font-size: 20px;
        font-family: montserratRegular;
        line-height: 36px;
        margin-top: 20px;
    }

    .left-section h1 {
        font-size: 32px;
        font-family: montserratSemiBold;
        line-height: 50px;
    }

    .left-section span {
        font-size: 16px;
        font-family: montserratRegular;
        line-height: 30px;
    }
}