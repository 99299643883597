.footer_main_container {
  display: flex;
  flex-direction: column;
  background: linear-gradient(45deg, #001F7A, #2F80D8);
  padding: 4rem 6rem 1rem 6rem;
}

.footer_container {
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.footer_row_view2 {
  display: flex;
  flex-direction: column;
  width: 35%;
  margin-right: 50px;
}

.footer_row_view2 img {
  width: 48px;
  height: 48px;
}

.footer_row_view2 span {
  font-size: 20px;
  font-family: montserratRegular;
  line-height: 30px;
  padding-bottom: 25px;
  padding-top: 10px;
  color: #E2E2E2;
  cursor: pointer;
  width: 80%;
}

.footer_social_top_view {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 30px;
}

.footer_social_top_view img {
  cursor: pointer;
  width: 48px !important;
  height: 48px !important;
  border-radius: 20px;
  margin-right: 10px;
}

.footer_row_view {
  display: flex;
  flex-direction: column;
  width: 20%;
}

.footer_row_view h2 {
  font-family: montserratSemiBold;
  font-size: 20px;
  line-height: 30px;
  color: #FFFFFF;
  padding-bottom: 18px;
}

.footer_row_view span {
  font-size: 16px;
  font-family: montserratMedium;
  line-height: 24px;
  padding-bottom: 15px;
  color: #E2E2E2;
  cursor: pointer;
}

.footer_row_view div {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.footer_row_view img {
  width: 19px;
  height: 19px;
  margin-right: 10px;
}

.line-divider {
  margin-top: 40px;
  width: 100%;
  background-color: #E2E2E2;
  height: 1px;
}

.footer_copy_right {
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 24px;
  font-family: montserratRegular;
  align-self: center;
  color: #E2E2E2;
}

.footer_row_view2 .logo_image {
  width: 70px;
  height: 70px;
}

@media (max-width: 991px) {
  .footer_container {
    flex-direction: column;
  }

  .footer_row_view2 {
    width: 100%;
  }

  .footer_row_view h2 {
    margin-top: 20px;
  }

  .footer_container {
    justify-content: flex-start;
  }

  .footer_row_view2,
  .footer_row_view {
    width: 100%;
  }

  .footer_row_view2 img,
  .footer_social_top_view img,
  .footer_row_view img {
    width: 20px;
    height: 20px;
  }

  .footer_row_view2 span,
  .footer_row_view span {
    font-size: 18px;
  }
}

@media (max-width: 800px) {
  .footer_main_container {
    padding: 2rem 2rem 1rem 2rem;
  }
}