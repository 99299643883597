/* mainSlider.css */

.reviews-container {
    color: #fff;
    align-self: center;
}

.reviews-container-padding {
    padding: 4rem 6rem 0rem 6rem;
    text-align: center;
}

.reviews-logo-container {
}

.reviews-logo-container img {
    width: 100px;
    height: 100px;
}

.reviews-logo-title {
    font-size: 16px;
    line-height: 24px;
    font-family: montserratMedium;
}

.reviews-title {
    font-size: 48px;
    font-family: montserratSemiBold;
    margin-top: 20px;
    line-height: 72px;
    /* Add additional styling for the title if needed */
}

.reviews-title-description {
    font-size: 20px;
    font-family: montserratRegular;
    margin-top: 20px;
    line-height: 30px;
    color: #E2E2E2;
    /* Add additional styling for the title if needed */
}

.reviews-description {
    margin-top: 10px;
    /* Add styling for the description */
}

.review-custom-button {
    margin-top: 30px;
    padding: 10px 50px;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: -0.3px;
    border: none;
    cursor: pointer;
    border-radius: 2em;
    font-family: montserratSemiBold;
    margin-bottom: 50px;
}

.review-list-item {
    display: flex;
}

.review-item-image img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin-right: 20px;
    /* To create a circular profile image */
}

.review-item-details {
    flex: 1 1 auto;
}

.review-item-name {
    font-size: 20px;
    font-family: montserratSemiBold;
    line-height: 30px;
    color: #313131;
}

.review-item-rating {
    font-size: 16px;
    margin-left: 2px;
    font-family: montserratLight;
    line-height: 24px;
    color: #616569;
}

.review-item-description {
    margin-top: 10px;
    font-size: 20px;
    font-family: montserratRegular;
    line-height: 30px;
    letter-spacing: 0.32px;
    color: #616569;
}

.scrollable-list-container {
    width: 100%;
    overflow-x: auto;
}

.scrollable-list {
    display: flex;
    overflow-x: auto;
    /* Firefox */
    scrollbar-color: transparent transparent;
}

::-webkit-scrollbar {
    width: 5px;
    /* Adjust width as needed */
}

::-webkit-scrollbar-thumb {
    background-color: transparent;
    /* Chrome/Safari/Edge */
}

.list-item-call {
    flex: 0 0 auto;
    margin-right: 20px;
    margin-left: 20px;
    /* Adjust the margin between items as per your design */
    display: flex;
    align-items: center;
    /* justify-content: center; */
    width: 30%;
    /* Adjust the width based on your preference */
    background-color: white;
    /* border: 1px solid #E2E2E2; */
    border-radius: 1rem;
    padding: 1rem 1rem 1rem 1rem;
    margin: 10px;
}

.left-right-arrowicons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0rem 10rem 3rem 10rem;
}

.left-right-arrowicons img {
    width: 48px;
    height: 48px;
    margin: 5px;
}

.review-rating {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.review-rating img {
    width: 24px;
    height: 24px;
    margin-right: 5px;
}

@media (max-width: 800px) {
    .reviews-container-padding {
        text-align: center;
        padding: 2rem 2rem 0rem 2rem;
    }

    .reviews-title {
        font-size: 30px;
        line-height: 42px;
        /* Add additional styling for the title if needed */
    }

    .reviews-title-description {
        font-size: 14px;
        line-height: 20px;
        /* Add additional styling for the title if needed */
    }

    .left-right-arrowicons {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 2rem 2rem 2rem 2rem;
    }

    .left-right-arrowicons img {
        width: 40px;
        height: 40px;
    }

    .review-item-image img {
        width: 60px;
        height: 60px;
    }

    /* .left-right-arrowicons {
        display: none;
    } */

    /* .scrollable-list {
        display: flex;
        flex-direction: column;
    } */

    .list-item-call {
        flex: 0 0 auto;
        margin-right: 10px;
        margin-left: 10px;
        width: 300px;
        margin: 1rem 0rem 0rem 1rem;
    }

    .review-rating img {
        width: 15px;
        height: 15px;
        margin-right: 5px;
    }

    .review-item-name {
        font-size: 14px;
    }

    .review-item-rating {
        font-size: 12px;
    }

    .review-item-description {
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0px;
    }
}