.alpha_loader_main_container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    background-color: rgba(255, 255, 255, 0.75);
    z-index: 999;
    width: 100vw;
    height: 100vh;
}

.alpha_loader_style {
    width: 30rem;
    height: 30rem;
    object-fit: contain;
}

@media screen and (max-width:768px) {
    .alpha_loader_style {
        width: 25rem;
        height: 25rem;
    }
}

@media screen and (max-width:425px) {
    .alpha_loader_style {
        width: 20rem;
        height: 20rem;
    }
}