.button_container {
  display: flex;
  width: 15rem;
  height: 3rem;
  background-color: #001F7A;
  border-radius: 50px;
  justify-content: center;
  align-items: center;
  align-self: center;
  cursor: pointer;
}

.button_container_disable {
  display: flex;
  width: 15rem;
  height: 3rem;
  background-color: #001F7A;
  border-radius: 50px;
  justify-content: center;
  align-items: center;
  align-self: center;
  cursor: pointer;
}

.button_container_disable,
.button_container p {
  font-size: 18px;
  font-family: montserratSemiBold;
  color: #ffffff;
}
