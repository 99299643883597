/* ContainerComponent.css */
.main-container {
    text-align: center;
    /* Add additional styling for the main container if needed */
    padding: 4rem 6rem 4rem 6rem;
}

.logo-container img {
    width: 100px;
    height: 100px;
}

.logo-title {
    background: linear-gradient(45deg, #001F7A, #2F80D8);
    /* Gradient color */
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    font-size: 16px;
    line-height: 24px;
    font-family: montserratMedium;
}

.title {
    font-size: 48px;
    font-family: montserratSemiBold;
    margin-top: 20px;
    line-height: 72px;
    color: #1e1e1e;
    /* Add additional styling for the title if needed */
}

.title-description {
    font-size: 20px;
    font-family: montserratRegular;
    margin-top: 20px;
    line-height: 30px;
    color: #666666;
    /* Add additional styling for the title if needed */
}

.list-container {
    display: flex;
    justify-content: space-around;
    margin-top: 50px;
    /* Add additional styling for the list container if needed */
}

.list-item {
    width: 30%;
    /* Adjust the width based on your preference */
    background-color: white;
    border: 1px solid #E2E2E2;
    border-radius: 2rem;
    padding: 2rem 2rem 2rem 2rem;
    /* Border color */
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
    /* Ensure the positioning context for z-index */
    /* Shadow */
    /* Add additional styling for the list items if needed */
}

.list-item:hover {
    z-index: 2;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    transform: translateY(-5px);
    cursor: pointer;
    /* Adjust as needed for the desired effect */
}

.item-image {
    width: 200px;
    height: 200px;
    height: auto;
    /* Adjust the width based on your preference */
    /* Add additional styling for the item image if needed */
}

.item-title {
    font-size: 24px;
    font-family: montserratSemiBold;
    margin-top: 20px;
    margin-bottom: 20px;
    line-height: 30px;
    color: #1E1E1E;
    /* Add additional styling for the item title if needed */
}

.item-description {
    font-size: 16px;
    font-family: montserratRegular;
    line-height: 24px;
    color: #666666;
    /* Add styling for the item description */
}

@media (max-width: 1050px) {
    .list-container {
        flex-direction: column;
        /* Switch to column layout at 1250px or less */
        align-items: center;
        /* Center items in column layout */
    }

    .list-item {
        width: 80%;
        /* Adjust width for better spacing in column layout */
        margin-bottom: 20px;
        /* Add margin between items in column layout */
    }

    .title {
        font-size: 42px;
        line-height: 62px;
        /* Add additional styling for the title if needed */
    }

    .title-description {
        font-size: 16px;
        line-height: 24px;
        /* Add additional styling for the title if needed */
    }
}


@media (max-width: 800px) {
    .main-container {
        text-align: center;
        /* Add additional styling for the main container if needed */
        padding: 2rem 2rem 0rem 2rem;
    }

    .title {
        font-size: 30px;
        line-height: 42px;
        /* Add additional styling for the title if needed */
    }

    .title-description {
        font-size: 14px;
        line-height: 20px;
        /* Add additional styling for the title if needed */
    }

    .list-item {
        width: 100%;
        padding: 2rem 1rem 2rem 1rem;
    }

}