.benefit-main-container {
    padding: 4rem 6rem 4rem 6rem;
}

.benefit-main-container-centerAlign {
    text-align: center;
}

.benefit-logo-container img {
    width: 100px;
    height: 100px;
}

.benefit-logo-title {
    background: linear-gradient(45deg, #001F7A, #2F80D8);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    font-size: 16px;
    line-height: 24px;
    font-family: montserratMedium;
}

.benefit-title {
    font-size: 48px;
    font-family: montserratSemiBold;
    margin-top: 20px;
    line-height: 72px;
    color: #1E1E1E;
}

.benefit-title-description {
    font-size: 20px;
    font-family: montserratRegular;
    margin-top: 20px;
    line-height: 30px;
    color: #666666;
}

.benefit-list-container {
    display: flex;
    justify-content: space-around;
    align-self: center;
    margin-top: 50px;
}

.benefit-list-item {
    width: 30%;
    background-color: white;
    border-radius: 1rem;
    padding: 1rem;
    margin: 0rem 1rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.benefit-list-item:hover {
    z-index: 2;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    transform: translateY(-5px);
}

.benefit-item-image {
    width: 60px;
    height: 60px;
}

.benefit-item-title {
    font-size: 16px;
    font-family: montserratSemiBold;
    margin-top: 10px;
    line-height: 24px;
    color: #1E1E1E;
}

.benefit-item-description {
    font-size: 14px;
    font-family: montserratRegular;
    margin-top: 10px;
    line-height: 22px;
    color: #666666;
}

.benefit-item-description span {
    color: #001F7A;
    font-family: montserratSemiBold;
}

.whatsappImage {
    width: 100px;
    height: auto;
}

/* For tablets and small desktops */
@media (max-width: 1024px) {
    .benefit-list-container {
        flex-direction: column;
        align-items: center;
    }

    .benefit-list-item {
        width: 100%;
        margin-bottom: 2rem;
    }
}

/* For landscape phones and tablets */
@media (max-width: 768px) {
    .benefit-main-container {
        padding: 1rem;
    }

    .benefit-title {
        font-size: 38px;
        line-height: 48px;
    }

    .benefit-title-description {
        font-size: 18px;
        line-height: 28px;
    }
}

/* For portrait phones */
@media (max-width: 576px) {
    .benefit-list-item {
        width: 100%;
    }

    .benefit-item-title {
        font-size: 14px;
        line-height: 22px;
    }

    .benefit-item-description {
        font-size: 12px;
        line-height: 20px;
    }
}