/* Header.css */
.main-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #ffffff;
    /* Header background color */
    /* padding: 15px; */
    padding-right: 90px;
    padding-left: 90px;
}

.logo img {
    width: 100px;
    /* Adjust the logo width as needed */
    height: auto;
}

.navigation ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
}

.navigation li {
    margin-right: 20px;
}

.navigation a {
    text-decoration: none;
    color: black;
    font-family: montserratSemiBold;
    font-size: 14px;
    padding: 5px 10px;
}

.navigation a.highlighted {
    background: linear-gradient(45deg, #001F7A, #2F80D8);
    /* Gradient color */
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    /* Make the text transparent */
    /* Highlighted color for the active option */
}

.user-options a {
    text-decoration: none;
    margin-left: 10px;
    padding: 10px 20px;
    border-radius: 50px;
    font-family: montserratSemiBold;
    font-size: 14px;
}

.user-options a.login {
    background-color: transparent;
    /* Login button color */
}

.user-options a.register {
    background-color: #001F7A;
    color: white;
    /* Register button color */
}

/* ProfileIcon.css */
.profile-icon-container {
    position: absolute;
    top: 2.5%;
    right: 3%;
}

.profile-icon {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.profile-img {
    width: 5.5vh;
    height: 5.5vh;
    border-radius: 50%;
    border: 1px solid #f1f1f1;
    /* Changed border-color to border */
}

.arrowDown {
    width: 15%;
    margin-left: 4%;
    height: auto;
}

.dropdown-arrow {
    margin-left: 8px;
    /* Adjust the spacing as needed */
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    right: 0;
    width: 180px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1;
}

.dropdown-option {
    display: flex;
    align-items: center;
    padding: 10px 2px 10px 10px;
    font-size: 14px;
    line-height: 24px;
    color: #1E1E1E;
    font-family: montserratRegular;
    cursor: pointer;
}

.dropdown-option span {
    margin-left: 1rem;
}

.dropdown-optionHighlighted {
    background: linear-gradient(45deg, #001F7A, #2F80D8);
    /* Gradient color */
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
}

.dropdown-option img {
    width: 15%;
    height: auto;
}

.toggle-button {
    display: none;
    padding: 1px;
    padding-right: 3px;
    padding-left: 3px;
}

.open-dropDown-user {
    display: none;
}

.close-dropDown-user {
    display: block;
}


/* ************************** Media Queries Styling ************************* **/
@media screen and (max-width: 1100px) {
    .navigation a {
        font-size: 12px;
        padding: 0px 5px;
    }

    .user-options a {
        margin-left: 10px;
        padding: 5px 10px;
        font-size: 12px;
    }
}

@media screen and (max-width: 900px) {
    .navigation a {
        font-size: 12px;
        padding: 0px 0px;
    }

    .user-options a {
        margin-left: 5px;
        padding: 5px 10px;
        font-size: 10px;
    }
}

@media screen and (max-width: 800px) {
    .logo img {
        width: 80px;
        height: auto;
    }

    .toggle-button {
        display: block;
    }

    .open-dropDown-user {
        display: block;
    }

    .close-dropDown-user {
        display: none;
    }

    .toggle-button img {
        width: 30px;
        height: auto;
    }

    .user-options a {
        display: none;
    }

    .profile-icon {
        justify-content: flex-end;
    }

    .navigation ul {
        display: none;
    }

    .main-header {
        padding-right: 20px;
        padding-left: 30px;
    }

    .open-dropDown-user {
        display: block;
    }
}