/* ContainerComponent.css */
.motorBike-main-container {
    text-align: center;
    /* Add additional styling for the main container if needed */
    padding: 4rem 6rem 4rem 6rem;
}

.motorBike-logo-container img {
    width: 100px;
    height: 100px;
}

.motorBike-logo-title {
    background: linear-gradient(45deg, #001F7A, #2F80D8);
    /* Gradient color */
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    font-size: 16px;
    line-height: 24px;
    font-family: montserratMedium;

}

.motorBike-title {
    font-size: 48px;
    font-family: montserratSemiBold;
    margin-top: 20px;
    line-height: 72px;
    color: #1E1E1E;
    /* Add additional styling for the title if needed */
}

.motorBike-title-description {
    font-size: 20px;
    font-family: montserratRegular;
    margin-top: 20px;
    line-height: 30px;
    color: #666666;
    text-align: left;
    /* Add additional styling for the title if needed */
}

.motorBike-list-container {
    display: flex;
    justify-content: space-around;
    align-self: center;
    margin-top: 50px;
    /* Add additional styling for the list container if needed */
}

.motorBike-list-container img {
    width: 282px;
    height: 267px;
    /* Adjust the width based on your preference */
    border-radius: 20px;

}

.motorBike-title-below {
    font-size: 20px;
    font-family: montserratSemiBold;
    margin-top: 20px;
    line-height: 30px;
    color: #1E1E1E;
}

.motorBike-bolitpoint {
    font-size: 18px;
    font-family: montserratRegular;
    margin-top: 5px;
    margin-left: 5px;
    line-height: 26px;
    color: #666666;
}


/* For tablets and small desktops */
@media (max-width: 1024px) {
    .motorBike-list-container {
        flex-wrap: wrap;
    }

    .motorBike-list-container img {
        width: 45%;
        /* Slightly less than half the container's width to fit two side by side */
        margin-bottom: 20px;
        /* Add some space between the images */
    }
}

/* For landscape phones and tablets */
@media (max-width: 800px) {
    .motorBike-main-container {
        padding: 2rem;
    }

    .motorBike-title {
        font-size: 38px;
        line-height: 48px;
    }

    .motorBike-title-description,
    .motorBike-title-below,
    .motorBike-bolitpoint {
        font-size: 14px;
        line-height: 24px;
        margin-left: 0px;
    }

    .motorBike-list-container img {
        width: 80%;
        /* Increase width for smaller screens */
        height: auto;
        /* Maintain aspect ratio */
        margin: 20px auto;
        /* Center the images with auto margins */
    }
}

/* For portrait phones */
@media (max-width: 576px) {
    .motorBike-list-container img {
        width: 90%;
        /* Further increase width for very small screens */
    }

    .motorBike-list-container {
        /* Stack images and text in a single column */
        flex-direction: column;
        align-items: center;
    }
}