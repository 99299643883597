.signin-auth-container {
    display: flex;
}

.signin-scroll {
    overflow: scroll;
    scroll-behavior: smooth;
    height: 100vh;
    width: 40%;
}

.signin-detail_view {
    display: flex;
    padding-bottom: 1rem;
    flex-direction: column;
    flex: 1;
    /* align-items: center; */
}

.signin-detail_view div {
    display: flex;
    flex-direction: column;
}

.signin-detail_view h1 {
    align-self: center;
    text-align: center;
    font-family: montserratSemiBold;
    font-size: 28px;
    color: #1E1E1E;
}

.signin-detail_view h2 {
    align-self: center;
    font-family: montserratRegular;
    padding-bottom: 4%;
    padding-top: 1rem;
    font-size: 14px;
    line-height: 26px;
    text-align: center;
    color: #666666;
    width: 30rem;
}

.signin-forget-password-Container {
    width: 30rem;
}

.signin-remember-me-label {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-family: montserratSemiBold;
}

.signin-checkbox {
    margin-right: 5px;
    height: 1rem;
    width: 1rem;
}

.signin-forgot_password_view {
    /* display: flex;
    align-self: flex-end; */
    cursor: pointer;
    /* margin-right: 3rem;
    margin-left: 3rem; */
}

.signin-forgot_password_view h3 {
    font-size: 14px;
    color: #FF8A1B;
    font-family: montserratSemiBold;
}

.signin-button-view {
    margin-top: 2rem;
    margin-bottom: 1rem;
}

.signin-or-divider {
    display: flex;
    align-items: center;
    flex-direction: row !important;
    align-self: center;
    width: 80%;
    margin: 30px 0;
    /* Adjust margin as needed */
}

.signin-line {
    flex-grow: 1;
    height: 0.5px;
    background-color: #E2E2E2;
    /* Line color */
}

.signin-or-text {
    margin: 0 20px;
    /* Adjust margin as needed */
    color: #666666;
    font-size: 18px;
    font-family: montserratRegular;
    /* Text color */
}

.signin-social_view {
    display: flex;
    flex-direction: row !important;
    align-self: center;
}

.signin-social_view div {
    display: flex;
    width: 7rem;
    height: 3rem;
    border-radius: 2rem;
    border: 0.8px solid #E2E2E2;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    /* margin-right: 1.5rem; */
}

.signin-social_view img {
    width: 2rem;
    height: 2rem;
    object-fit: cover;
}

.signin-dont_have_account_view {
    display: flex;
    align-self: center;
    cursor: pointer;
}

.signin-dont_have_account_view h3 {
    font-size: 14px;
    font-family: montserratRegular;
    color: #8E8E94;
}

/* ************************** Common Styling ************************* **/
.signin-image_sideView {
    display: flex;
    /* height: 100%; */
    height: 100vh;
    width: 60%;
}

.signin-image_logo_style {
    width: 5rem;
    height: 5rem;
    margin: 1rem;
}

.signin-image_sideStyle {
    height: 100%;
    width: 100%;
}

.image-caption {
    position: absolute;
    top: 50%;
    left: 70%;
    transform: translate(-50%, -50%);
    background-color: transparent;
    padding: 10px;
    width: 45%;
    color: #fff;
}

.image-title {
    font-family: montserratMedium;
    font-size: 28px;
    letter-spacing: -0.3px;
    color: #FAFAFA;
    line-height: 42px;
}

.image-description {
    font-family: montserratSemiBold;
    font-size: 64px;
    letter-spacing: -0.3px;
    color: #FFFFFF;
    line-height: 96px;
    /* Adjust line height as needed */
}

/* ************************** Media Queries Styling ************************* **/
@media screen and (max-width: 1350px) {
    .image-caption {
        top: 50%;
        left: 70%;
    }

    .image-title {
        font-size: 24px;
    }

    .image-description {
        font-size: 44px;
    }
}

@media screen and (max-width: 1250px) {
    .signin-image_sideView {
        display: none;
    }

    .signin-scroll {
        width: 100%;
    }
}

@media screen and (max-width: 500px) {
    .signin-detail_view h1 {
        font-size: 20px;
    }

    .signin-detail_view h2 {
        font-size: 12px;
        width: 20rem;
        line-height: 16px;
    }

    .signin-remember-me-label {
        font-size: 12px;
    }

    .signin-forget-password-Container {
        width: 20rem;
    }

    .signin-social_view div {
        width: 5rem;
        height: 2rem;
    }

    .signin-image_logo_style {
        width: 3rem;
        height: 3rem;
        margin: 1rem;
    }

    .signin-social_view img {
        width: 1.5rem;
        height: 1.5rem;
        object-fit: cover;
    }

    .signin-or-divider {
        width: 70%;
        /* Adjust margin as needed */
    }
}