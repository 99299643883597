/* Default styles for large screens */
.contract-container {
    display: flex;
    background: transparent;
    color: #fff;
    padding: 4rem 6rem 4rem 6rem;
}

.contract-right-section {
    flex: 1;
    align-self: center;
}

.contract-right-section img {
    width: 580px;
    height: 449px;
}

.contract-left-section {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

.contract-left-section p {
    font-size: 24px;
    font-family: montserratMedium;
    line-height: 36px;
    margin-top: 20px;
    background: linear-gradient(45deg, #001F7A, #2F80D8);
    /* Gradient color */
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
}

.contract-left-section h1 {
    font-size: 48px;
    font-family: montserratSemiBold;
    line-height: 60px;
    color: #1e1e1e;
}

.contract-left-section p {
    font-size: 20px;
    font-family: montserratRegular;
    line-height: 30px;
    color: rgba(102, 102, 102, 1);
    margin-top: 10px;
}

.contract-left-section p span {
    color: #001F7A;
    text-decoration-line: underline;
    font-family: montserratMedium;
}

/* Media Query for smaller screens */
@media screen and (max-width: 1050px) {
    .contract-container {
        display: flex;
        flex-direction: column;
        /* Change to column layout */
        padding: 2rem;
        /* Reduce padding for smaller screens */
    }

    .contract-right-section img {
        width: 100%;
        /* Make the image width 100% of its container */
        height: auto;
        /* Allow image height to adjust proportionally */
    }

    .contract-left-section {
        padding: 10px;
        /* Reduce padding for smaller screens */
    }
}


@media screen and (max-width: 800px) {
    .contract-left-section p {
        font-size: 20px
    }

    .contract-left-section h1 {
        font-size: 26px;
    }

    .contract-left-section p {
        font-size: 16px;
    }
}