.custome-main-container {
    padding: 4rem 6rem 4rem 6rem;
}

.custome-main-container-centerAlign {
    text-align: center;
}

.custome-logo-container img {
    width: 100px;
    height: 100px;
}

.custome-logo-title {
    background: linear-gradient(45deg, #001F7A, #2F80D8);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    font-size: 16px;
    line-height: 24px;
    font-family: montserratMedium;
}

.custome-title {
    font-size: 48px;
    font-family: montserratSemiBold;
    margin-top: 20px;
    line-height: 72px;
    color: #1E1E1E;
}

.custome-title-description {
    font-size: 20px;
    font-family: montserratRegular;
    margin-top: 20px;
    line-height: 30px;
    color: #666666;
    text-align: left;
}

.custome-list-container {
    display: flex;
    margin-top: 50px;
    width: 100%;
    justify-content: space-between;
}

.dynamic-map-container {
    width: 100%;
    /* Full width */
    margin-left: 50px;
    /* padding-top: 56.25%; */
    /* Percentage for 16:9 aspect ratio */
    position: relative;
    /* For absolute positioning of the map inside */
}

.dynamic-map {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}


/* For tablets and small desktops */
@media (max-width: 1024px) {
    .custome-list-container {
        flex-direction: column;
    }

    .dynamic-map-container {
        width: 100%;
        /* Full width */
        padding-top: 56.25%;
        margin-left: 0px;
        /* Percentage for 16:9 aspect ratio */
        position: relative;
        /* For absolute positioning of the map inside */
    }
}

/* For landscape phones and tablets */
@media (max-width: 800px) {
    .custome-main-container {
        padding: 2rem;
    }

    .custome-title {
        font-size: 38px;
        line-height: 48px;
    }

    .custome-title-description {
        font-size: 18px;
        line-height: 28px;
    }

    .runner-dynamic-map-container {
        width: 100%;
        /* Full width */
        padding-top: 86.25%;
        margin-left: 30px;
        /* Percentage for 16:9 aspect ratio */
        position: relative;
        /* For absolute positioning of the map inside */
    }
}

/* For portrait phones */
@media (max-width: 576px) {
    .custome-list-container {
        flex-direction: column;
    }

    .custome-title {
        font-size: 28px;
        line-height: 36px;
    }

    .custome-title-description {
        font-size: 16px;
        line-height: 24px;
    }


}