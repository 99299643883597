.vision-container {
    display: flex;
    background: transparent;
    color: #fff;
    padding: 4rem 10rem 4rem 6rem;
}

.vision-left-section {
    flex: 1;
    align-self: center;
}

.vision-left-section img {
    width: 450px;
    height: 500px;
}

.vision-right-section {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 30px;
}

.vision-right-section p {
    font-size: 24px;
    font-family: montserratMedium;
    line-height: 36px;
    margin-top: 20px;
    background: linear-gradient(45deg, #001F7A, #2F80D8);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
}

.vision-right-section h1 {
    font-size: 48px;
    font-family: montserratSemiBold;
    line-height: 64px;
    color: #1A1A1A;
    padding-bottom: 20px;
}

.vision-right-section span {
    font-size: 20px;
    font-family: montserratRegular;
    line-height: 30px;
    color: #666666;
}

/* For tablets and small desktops */
@media (max-width: 1300px) {
    .vision-container {
        padding: 4rem;
    }

    .vision-left-section img {
        width: 100%;
        height: auto;
    }

    .vision-right-section h1 {
        font-size: 38px;
        line-height: 48px;
    }

    .vision-right-section p {
        font-size: 18px;
        line-height: 28px;
    }

    .vision-right-section span {
        font-size: 16px;
        line-height: 24px;
    }
}

/* For landscape phones and tablets */
@media (max-width: 800px) {
    .vision-container {
        flex-direction: column;
        padding: 2rem;
    }

    .vision-left-section,
    .vision-right-section {
        flex: none;
        width: 100%;
        align-items: center;
        text-align: left;
    }

    .vision-right-section {
        padding: 0;
    }

    /* .vision-right-section p,
    .vision-right-section h1,
    .vision-right-section span {
        background: none;
        -webkit-background-clip: initial;
        background-clip: initial;
        color: #1A1A1A;
    } */
}

/* For portrait phones */
@media (max-width: 600px) {
    .vision-right-section h1 {
        font-size: 28px;
        line-height: 36px;
    }

    .vision-right-section p {
        font-size: 16px;
        line-height: 24px;
    }

    .vision-right-section span {
        font-size: 14px;
        line-height: 22px;
    }
}