.profile-auth-container {
    display: flex;
}

.profile-scroll {
    overflow: scroll;
    scroll-behavior: smooth;
    height: 100vh;
    width: 40%;
}

.profile-detail_view {
    display: flex;
    padding-bottom: 1rem;
    flex-direction: column;
    flex: 1;
    /* align-items: center; */
}

.profile-detail_view div {
    display: flex;
    flex-direction: column;
}

.profile-detail_view h1 {
    align-self: center;
    text-align: center;
    font-family: montserratSemiBold;
    font-size: 28px;
    color: #1E1E1E;
}

.profile-detail_view h2 {
    align-self: center;
    font-family: montserratRegular;
    padding-bottom: 4%;
    padding-top: 1rem;
    font-size: 14px;
    line-height: 26px;
    text-align: center;
    color: #666666;
    width: 30rem;
}

.profile-button-view {
    margin-top: 1rem;
}

/* CircleView.css */
.profile-circle-view {
    position: relative;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    background-color: #F1F1FB;
    border: 1px solid #f1f1f1;
    /* You can change the background color as needed */
    align-self: center;
    position: relative;
}

.profile-camera-icon {
    position: absolute;
    bottom: 0px;
    /* Adjust to move the camera icon slightly outside */
    right: 10px;
    /* Adjust to move the camera icon slightly outside */
    width: 25px;
    height: 25px;
    background-color: #001F7A;
    /* You can change the icon background color as needed */
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 2;
    /* Set a higher z-index for the camera icon */
}

.profile-camera-icon img {
    width: 15px;
    /* Adjust the icon size as needed */
    height: 15px;
}

/* ************************** Common Styling ************************* **/
.profile-image_sideView {
    display: flex;
    height: 100vh;
    width: 60%;
}

.profile-image_sideStyle {
    height: 100%;
    width: 100%;
}

.profile-image_logo_style {
    width: 5rem;
    height: 5rem;
    margin: 1rem;
}

.google_input_container {
    margin-top: 0.6rem;
    flex-direction: row !important;
    width: 30rem;
    background-color: rgba(217, 217, 217, 0.1);
    border-radius: 0.5rem;
    /* border: 1px solid #D9D9D9; */
    padding: 0.5rem 1rem 0.5rem 1rem;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.google_input_container input {
    width: 90% !important;
    display: flex;
    outline: none;
    border: none;
    font-size: 16px;
    line-height: 2rem;
    color: #000000;
    background-color: transparent;
}

.google_input {
    display: flex;
    flex-direction: column;
    margin-bottom: 1.4rem;
    position: relative;
    align-self: center;
}

.google_input p {
    font-size: 16px;
    font-family: montserratSemiBold;
    line-height: 26px;
    color: #0E191E;
}

/* ************************** Media Queries Styling ************************* **/
@media screen and (max-width: 1250px) {
    .profile-image_sideView {
        display: none;
    }

    .profile-scroll {
        width: 100%;
    }
}

@media screen and (max-width: 500px) {
    .google_input_container {
        width: 20rem;
    }

    .google_input_container input {
        width: 90% !important;
        font-size: 14px;
    }

    .profile-detail_view h1 {
        font-size: 20px;
    }

    .profile-detail_view h2 {
        font-size: 10px;
        width: 20rem;
        line-height: 16px;
    }

    .profile-image_logo_style {
        width: 3rem;
        height: 3rem;
        margin: 1rem;
    }
}