.otp-auth-container {
    display: flex;
}

.otp-scroll {
    overflow: scroll;
    scroll-behavior: smooth;
    height: 100vh;
    width: 40%;
}

.otp-detail_view {
    display: flex;
    padding-bottom: 1rem;
    flex-direction: column;
    flex: 1;
    /* align-items: center; */
}

.otp-detail_view h1 {
    align-self: center;
    text-align: center;
    font-family: montserratSemiBold;
    font-size: 28px;
    color: #1E1E1E;
}

.otp-detail_view h2 {
    align-self: center;
    font-family: montserratRegular;
    padding-bottom: 4%;
    padding-top: 1rem;
    font-size: 14px;
    line-height: 26px;
    text-align: center;
    color: #666666;
    width: 30rem;
}

.otp-button-view {
    margin-top: 3rem;
    align-self: center;
}

.otp-resend-div {
    margin-top: 2rem;
    align-self: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.otp-resent-OTP {
    font-family: montserratMedium;
    font-size: 24px;
    color: #FF8A1B;
}

.otp-input:focus {
    outline: none;
    /* Remove default focus outline */
    border-color: rgba(0, 31, 122, 1);
    /* Set border color to your desired color */
    box-shadow: none;
    /* Remove any box shadow */
}

.otp-resend-div span {
    font-family: montserratRegular;
    font-size: 12px;
    color: #666666;
    margin-top: 0.5rem;
    cursor: pointer;
}

/* ************************** Common Styling *************************** */
.otp-image_sideView {
    display: flex;
    height: 100vh;
    width: 60%;
}

.otp-image_sideStyle {
    height: 100%;
    width: 100%;
}

.otp-image_logo_style {
    width: 5rem;
    height: 5rem;
    margin: 1rem;
}


/* ************************** Media Queries Styling ************************* **/
@media screen and (max-width: 1000px) {
    .otp-image_sideView {
        display: none;
    }

    .otp-scroll {
        width: 100%;
    }
}

@media screen and (max-width: 500px) {
    .otp-detail_view h1 {
        font-size: 20px;
    }

    .otp-detail_view h2 {
        font-size: 10px;
        width: 20rem;
        line-height: 16px;
    }

    .otp-image_logo_style {
        width: 3rem;
        height: 3rem;
        margin: 1rem;
    }
}