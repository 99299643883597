/*  */

.privacy-policy-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.privacy-policy {
    width: 90%;
    padding: 20px;
    line-height: 1.6;
    align-items: center;
    justify-content: center;
}

.privacy-policy h1,
.privacy-policy h2,
.privacy-policy h3 {
    margin: 24px 0 8px 0;
}