.page_not_found_container {
    display: flex;
    width: 100vw;
    height: 100vh;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.page_not_found_container img {
    width: 30rem;
    height: 30rem;
    margin-bottom: 1rem;
}

.page_not_found_container h1 {
    font-weight: 700;
    font-size: 3rem;
    line-height: 4rem;
    text-align: center;
    color: #000000;
    padding-right: 2rem;
    padding-left: 2rem;
}

@media screen and (max-width:768px) {
    .page_not_found_container img {
        width: 20rem;
        height: 20rem;
    }

    .page_not_found_container h1 {
        font-size: 2rem;
        line-height: 3rem;
    }
}

@media screen and (max-width:425px) {
    .page_not_found_container img {
        width: 15rem;
        height: 15rem;
    }

    .page_not_found_container h1 {
        font-size: 1.5rem;
        line-height: 2rem;
    }
}