/* src/ComingSoon.css */

.coming-soon-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
    background-color: #f5f5f5;
    padding: 20px;
}

.coming-soon-container h1 {
    font-size: 2.5em;
    margin-bottom: 20px;
}

.coming-soon-container p {
    font-size: 1.2em;
    margin-bottom: 30px;
}

.subscribe {
    display: flex;
}

.subscribe input {
    padding: 10px;
    font-size: 1em;
    border: none;
    outline: none;
    border-radius: 5px 0 0 5px;
}

.subscribe button {
    padding: 10px;
    font-size: 1em;
    border: none;
    outline: none;
    background-color: blue;
    color: white;
    cursor: pointer;
    border-radius: 0 5px 5px 0;
}

.subscribe input:focus,
.subscribe button:focus {
    border-color: #666;
}