/* Base styles */
.delivery-main-container {
    text-align: center;
    padding: 4rem 1rem;
    /* Reduced base padding */
}

.delivery-logo-container img {
    width: 100px;
    height: 100px;
}

.delivery-logo-title,
.delivery-list-item {
    background: linear-gradient(45deg, #001F7A, #2F80D8);
    color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
}

.delivery-logo-title {
    font-size: 16px;
    line-height: 24px;
    font-family: 'Montserrat', sans-serif;
}

.delivery-title {
    font-size: 48px;
    /* Adjusted for better scaling */
    line-height: 72px;
    color: #1E1E1E;
    margin-top: 20px;
    font-family: montserratSemiBold;
}

.delivery-title-description {
    font-size: 20px;
    line-height: 30px;
    color: #666666;
    margin-top: 20px;
    padding: 0 1rem;
    font-family: montserratRegular;
}

/* Dynamic list styles */
.delivery-row-list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    /* Wrap items for smaller screens */
}

.delivery-list-item {
    padding: 1rem 0rem 1rem 0rem;
    width: 15rem;
    margin: 10px;
    border: 1px solid #0b5099;
    border-radius: 4rem;
    cursor: pointer;
    font-size: 20px;
    /* Adjusted for better scaling */
    line-height: 36px;
    color: linear-gradient(45deg, #001F7A, #2F80D8);
    font-family: montserratRegular;
}

.delivery-list-item.selected {
    color: white;
    background: linear-gradient(45deg, #001F7A, #2F80D8);
}

.delivery-list-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: flex-start;
    align-items: stretch;
}

/* Media queries for responsiveness */
@media (min-width: 768px) {
    .delivery-main-container {
        padding: 4rem;
        /* Increased padding for medium screens */
    }

    .delivery-title-description,
    .delivery-list-container {
        padding: 0rem 4rem;
    }

    .delivery-list-item {
        padding: 5px 40px;
        margin: 20px;
    }
}

@media (min-width: 1024px) {
    .delivery-main-container {
        padding: 4rem 10rem;
        /* Original padding for large screens */
    }

    .delivery-title {
        font-size: 3rem;
        /* Larger title font for large screens */
    }

    .delivery-title-description {
        padding: 0 10rem;
    }
}

@media (max-width: 600px) {
    .delivery-list-item {
        padding: 0.5rem 0rem 0.5rem 0rem;
        width: 15rem;
        margin: 10px;
        font-size: 16px;
        /* Adjusted for better scaling */
        line-height: 26px;
    }
}