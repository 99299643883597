/* ContainerComponent.css */
.voucherComp-main-container {
    /* Add additional styling for the main container if needed */
    padding: 4rem 10rem 2rem 10rem;
}

.voucherComp-main-container-centerAligned {
    text-align: center;
}

.voucher-template-container-center {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap; /* To allow multiple templates to wrap onto the next line */
}

/* VoucherCom.css */
.voucher-template-container {
    position: relative;
    width: 800px;
    height: 260px;
    display: flex;
    background-size: cover;
}

.voucher-template-content {
    position: absolute;
    top: 50%;
    left: 20%;
    transform: translate(-50%, -50%);
    color: #fff;
    /* Text color on the image */
}

.voucher-right-template-content {
    position: absolute;
    top: 50%;
    left: 250%;
    width: 150%;
    height: 100%;
    transform: translate(-50%, -50%);
    color: #000;
    /* Text color on the image */
}

.voucher-right-template-content h1 {
    font-size: 24px;
    line-height: 36px;
    font-family: montserratSemiBold;
    color: #1E1E1E;
}

.voucher-right-template-content h2 {
    font-size: 14px;
    line-height: 24px;
    font-family: montserratMedium;
    color: #666666;
    margin-top: 1rem;
}

.voucher-right-template-content p {
    font-size: 12px;
    line-height: 20px;
    font-family: montserratRegular;
    color: #666666;
    margin-top: 1rem;
}

.voucher-connector-line {
    position: absolute;
    top: 50%;
    left: 150%;
    transform: translate(-50%, -50%);
    border-left: 2px dashed #E2E2E2;
    height: 150px;
}

.voucher-template {
    width: 100px;
    /* Adjust the width of the image */
    height: auto;
    /* Maintain aspect ratio */
    margin-bottom: 10px;
    /* Adjust spacing between image and text */
}

.voucherComp-logo-container img {
    width: 100px;
    /* Adjust the logo width as needed */
    height: 100px;
}

.voucherComp-logo-title {
    background: linear-gradient(45deg, #001F7A, #2F80D8);
    /* Gradient color */
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    font-size: 16px;
    line-height: 24px;
    font-family: montserratMedium;
}

.voucherComp-title {
    font-size: 48px;
    font-family: montserratSemiBold;
    margin-top: 20px;
    line-height: 72px;
    color: #1E1E1E;
    /* Add additional styling for the title if needed */
}

.voucherComp-title-description {
    font-size: 20px;
    font-family: montserratRegular;
    margin-top: 20px;
    line-height: 30px;
    color: #666666;
    padding: 0rem 12rem 0rem 12rem;
    /* Add additional styling for the title if needed */
}