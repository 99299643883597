.resetPassword-auth-container {
    display: flex;
}

.resetPassword-scroll {
    overflow: scroll;
    scroll-behavior: smooth;
    height: 100vh;
    width: 40%;
}

.resetPassword-detail_view {
    display: flex;
    padding-bottom: 1rem;
    flex-direction: column;
    flex: 1;
    /* align-items: center; */
}

.resetPassword-detail_view div {
    display: flex;
    flex-direction: column;
}

.resetPassword-detail_view h1 {
    align-self: center;
    text-align: center;
    font-family: montserratSemiBold;
    font-size: 28px;
    color: #1E1E1E;
}

.resetPassword-detail_view h2 {
    align-self: center;
    font-family: montserratRegular;
    padding-bottom: 4%;
    padding-top: 1rem;
    font-size: 14px;
    line-height: 26px;
    text-align: center;
    color: #666666;
    width: 30rem;
}

.resetPassword-button-view {
    margin-top: 1rem;
}

/* ************************** Common Styling *************************** */
.resetPassword-image_sideView {
    display: flex;
    height: 100vh;
    width: 60%;
}

.resetPassword-image_sideStyle {
    height: 100%;
    width: 100%;
}

.resetPassword-image_logo_style {
    width: 5rem;
    height: 5rem;
    margin: 1rem;
}

/* ************************** Media Queries Styling ************************* **/
@media screen and (max-width: 1250px) {
    .resetPassword-image_sideView {
        display: none;
    }

    .resetPassword-scroll {
        width: 100%;
    }
}

@media screen and (max-width: 500px) {
    .resetPassword-detail_view h1 {
        font-size: 20px;
    }

    .resetPassword-detail_view h2 {
        font-size: 10px;
        width: 20rem;
        line-height: 16px;
    }

    .resetPassword-image_logo_style {
        width: 3rem;
        height: 3rem;
        margin: 1rem;
    }
}